@use "theme/variables" as *;

[data-tippy-root] {
    max-width: calc(100vw - 10px);
}

.tippy-arrow {
    color: $surface-5;
    height: 16px;
    width: 16px;

    &::before {
        border-color: transparent;
        border-style: solid;
        content: "";
        position: absolute;
    }
}

.tippy-box {
    background-clip: padding-box;
    background-color: $surface-6;
    border: 1px solid $surface-5;
    border-radius: 4px;
    box-shadow: $shadow-down-d;
    color: $surface-2;
    font-size: 14px;
    line-height: 1.4;
    outline: 0;
    position: relative;
    transition-property: transform, visibility, opacity;
    white-space: normal;

    > .tippy-backdrop {
        background-color: $surface-6;
    }

    > .tippy-arrow::after,
    > .tippy-svg-arrow::after {
        content: "";
        position: absolute;
        z-index: -1;
    }

    > .tippy-arrow::after {
        border-color: transparent;
        border-style: solid;
    }

    &[data-placement^="top"] {

        > .tippy-arrow {
            bottom: 0;

            &::after {
                border-top-color: rgb(0 8 16 / 20%);
                border-width: 7px 7px 0;
                left: 1px;
                top: 17px;
            }
        }

        > .tippy-arrow::before {
            border-top-color: $surface-6;
            border-top-color: initial;
            border-width: 8px 8px 0;
            bottom: -7px;
            left: 0;
            transform-origin: center top;
        }

        > .tippy-svg-arrow {

            > svg {
                top: 16px;
            }

            &::after {
                top: 17px;
            }
        }
    }


    &[data-placement^="bottom"] {

        > .tippy-arrow {
            top: 0;
        }

        > .tippy-arrow::before {
            border-bottom-color: $surface-6;
            border-bottom-color: initial;
            border-width: 0 8px 8px;
            bottom: 16px;
            left: 0;
            top: -7px;
            transform-origin: center bottom;
        }

        > .tippy-arrow::after {
            border-bottom-color: rgb(0 8 16 / 20%);
            border-width: 0 7px 7px;
            bottom: 17px;
            left: 1px;
        }

        > .tippy-svg-arrow > svg {
            bottom: 16px;
        }

        > .tippy-svg-arrow::after {
            bottom: 17px;
        }
    }

    &[data-placement^="left"] {

        > .tippy-arrow {
            right: 0;
        }

        > .tippy-arrow::before {
            border-left-color: $surface-6;
            border-left-color: initial;
            border-width: 8px 0 8px 8px;
            right: -7px;
            transform-origin: center left;
        }

        > .tippy-arrow::after {
            border-left-color: rgb(0 8 16 / 20%);
            border-width: 7px 0 7px 7px;
            left: 17px;
            top: 1px;
        }

        > .tippy-svg-arrow > svg {
            left: 11px;
        }

        > .tippy-svg-arrow::after {
            left: 12px;
        }
    }

    &[data-placement^="right"] {

        &[data-placement^="right"] > .tippy-arrow {
            left: 0;
        }

        > .tippy-arrow::before {
            border-right-color: $surface-6;
            border-width: 8px 8px 8px 0;
            left: -7px;
            right: 16px;
            transform-origin: center right;
        }

        > .tippy-arrow::after {
            border-right-color: rgb(0 8 16 / 20%);
            border-width: 7px 7px 7px 0;
            right: 17px;
            top: 1px;
        }

        > .tippy-svg-arrow > svg {
            right: 11px;
        }

        > .tippy-svg-arrow::after {
            right: 12px;
        }
    }


    &[data-inertia][data-state="visible"] {
        transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
    }

    &[data-animation="fade"][data-state="hidden"] {
        opacity: 0;
    }

    &[data-animation="scale"] {
        &[data-placement^="top"] { transform-origin: bottom; }
        &[data-placement^="bottom"] { transform-origin: top; }
        &[data-placement^="left"] { transform-origin: right; }
        &[data-placement^="right"] { transform-origin: left; }

        &[data-state="hidden"] {
            opacity: 0;
            transform: scale(0.5);
        }
    }


    > .tippy-svg-arrow {
        fill: $surface-6;

        &::after {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA2czEuNzk2LS4wMTMgNC42Ny0zLjYxNUM1Ljg1MS45IDYuOTMuMDA2IDggMGMxLjA3LS4wMDYgMi4xNDguODg3IDMuMzQzIDIuMzg1QzE0LjIzMyA2LjAwNSAxNiA2IDE2IDZIMHoiIGZpbGw9InJnYmEoMCwgOCwgMTYsIDAuMikiLz48L3N2Zz4=");
            background-size: 16px 6px;
            height: 6px;
            width: 16px;
        }
    }
}

.tippy-content {
    padding: $spacer-05 $spacer-1;
    position: relative;
    z-index: 1;
}

