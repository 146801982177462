@use "theme/variables" as *;

@mixin field-label {
    align-items: center;
    color: $default-1;
    color: $input-label-color;
    display: flex;
    font-weight: 600;
    gap: $spacer-05;
    height: $input-label-height;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .validation {
        color: $info-2;
        margin-left: $spacer-05;
    }

    i {
        margin-left: $spacer-05;
    }
}

.field {
    display: flex;
    flex-direction: column;
    margin-bottom: $input-margin;
    position: relative;

    select,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="date"] {
        transition: background 0.6s ease;
    }

    &.row {
        flex-direction: row;
    }

    &.hidden {
        display: none;
    }

    select {
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    label {
        @include field-label;
    }

    .flex-row {
        display: flex;
    }

    .checkbox {
        align-items: center;
        display: flex;
        margin: 0;
        padding: 0;

        label {
            font-weight: 600;
            margin-left: $spacer-1;
        }
    }

    .control {
        border-radius: 0;
        display: flex;
        flex: 1;
        width: 100%;

        .field {
            max-width: unset;
        }

        input[type="number"],
        input[type="text"] {
            border-bottom-right-radius: 0;
            border-right: 0;
            border-top-right-radius: 0;
            width: 100%;
        }

        button {
            border-radius: 0;
            height: $input-height;
        }

        .control-addon {
            align-items: center;
            background: $info-2;
            color: hsl(var(--surface-h) var(--s-1) var(--l-6));
            display: flex;
            font-weight: 600;
            height: $input-height;
            justify-content: center;
            min-width: 50px;
        }
    }

    .input-group-addon {
        background: $surface-2;
        color: $surface-5;
    }

    .help {
        bottom: -1 * $spacer-2;
        color: $default-2;
        font-size: $font-xs;
        font-style: italic;
        font-weight: 300;
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        &.validation {
            color: $danger-2;
        }

        .danger {
            color: $danger-2;
        }
    }

    &:not(:invalid) {
        // Keep legacy html5 validation styling intact

        select,
        textarea,
        input[type="text"],
        input[type="number"],
        input[type="date"] {

            :not(:disabled) {
                background: $input-bg;
                border: 1px solid $input-border-color;
            }
        }
    }

    &.center {
        justify-content: center;
    }

    &.disabled {

        label {
            color: $input-disabled-color;
        }

        select,
        textarea,
        input[type="date"],
        input[type="text"],
        input[type="password"] {
            background: $input-disabled-bg !important;
            border-color: $input-disabled-border;
            color: $input-disabled-color;
        }

        .control {

            .control-addon {
                background: hsl(var(--info-h) var(--s-1) var(--l-4));
            }
        }
    }

    &.invalid {

        label .validation {
            color: $danger-2;
        }

        select,
        textarea,
        input {

            &:not(:invalid) {
                border: 1px solid hsl(var(--danger-h) var(--s-3) var(--l-2));
            }
        }
    }

    &.valid {

        label .validation {
            color: $success-2;
        }

        select,
        textarea,
        input {

            &:not(:invalid) {
                border: 1px solid hsl(var(--success-h) var(--s-1) var(--l-3));
            }
        }
    }
}

.field-readonly {
    // Cart-like view on form data (see lead view for instance)
    background: $grey-6;
    border: 1px solid $grey-4;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: $spacer-1;
    padding: $spacer-05 $spacer-1;
    position: relative;

    .value,
    .key {
        flex: 1;
    }

    .key {
        color: $info-1;
        font-weight: 600;
    }
}
