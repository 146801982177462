@use "theme/variables" as *;
@use "sass:math";

@mixin appear($name) {
    /* stylelint-disable-next-line keyframes-name-pattern */
    @keyframes #{$name} {

        0% {
            background: $brand-3;
            color: $brand-1;
            transform: scale(1);
        }

        40% {
            transform: scale(1);
        }

        60% {
            transform: scale(1.5);
        }

        100% {
            background: $surface-4;
            color: $surface-6;
            transform: scale(1);
        }
    }
}


@mixin alert-variant($background, $border, $text-color) {
    background-color: $background;
    border: 2px dashed $border;
    color: $text-color;
}

@mixin on-circle($item-count, $circle-size, $item-size, $rot) {
    border-radius: 50%;
    height: $circle-size;
    list-style: none;
    padding: 0;
    position: relative;

    > .c-button {
        $angle: math.div(360, $item-count);

        height: $item-size;
        left: 50%;
        margin: $item-size * -0.5;
        position: absolute;
        top: 50%;
        width: $item-size;

        @for $i from 1 through $item-count {
            $rot: $rot + $angle;
            &:nth-of-type(#{$i}) {
                transform: rotate($rot * 1deg) translate($circle-size * 0.5) rotate($rot * -1deg);
            }
        }
    }
}

@include appear(toggle-panel-collapse);
@include appear(toggle-panel-uncollapse);

@mixin row-style($background, $bg_highlight, $foreground, $important: false) {
    // Normally, specificity is enough to target the right styling,
    // but tables in Discover have too many different structures
    // for this to work reliably, hence the important override.
    @if $important {
        background: $background !important;
    } @else {
        background: $background;
    }

    color: $foreground;

    &:not(.selected,.well):hover {
        background: $bg_highlight;
    }


    &.selected, // legacy table selector
    &.detail {
        background: $bg_highlight;
    }

    &.edit {
        background: $bg_highlight;
    }
}

@mixin td-group {

    .td-group {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .header {
            color: $info-1;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.stats {
                display: flex;
                gap: $spacer-1;
            }
        }

        .details {
            font-size: $font-s;

            &:not(.wrap) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &.wrap {
                overflow: unset;
                white-space: normal;
                white-space: wrap;
            }
        }

        .sub-details {
            font-size: $font-s;
            font-style: italic;
        }

        .marker {
            align-items: center;
            display: flex;
            gap: $spacer-05;
        }
    }
}


@mixin toggle {
    // Separate keyframe ids are needed, because animations can't be run again
    // without resetting the animation state; e.g. remove a css class that
    // contains the animation.
    align-items: center;
    background: $default-3;
    overflow: visible;

    &.selection {
        background: $brand-4;
    }

    &.active,
    &:hover {
        background: $brand-3;
        color: $white;
    }
}

@mixin item-tag($color) {
    background: $color;

    &::after {
        background: $color;
    }
}

// Keep images from scaling beyond the width of their parents.
@mixin img-responsive($display: block) {
    display: $display;
    height: auto;
    max-width: 100%;
}

@mixin square($size) {
    height: $size;
    width: $size;
}

@mixin even-row {
    background: $table-row-even-bg;

    &:hover {
        background: hsl(var(--default-h) var(--s-1) var(--l-5));
    }
}

@mixin odd-row {
    background: $table-row-odd-bg;

    &:hover {
        background: hsl(var(--default-h) var(--s-1) var(--l-5));
    }
}
