@use "theme/variables" as *;

// Source: https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    $input-size: 22px;

    input[type="checkbox"],
    input[type="radio"] {
        appearance: none;
        background: $input-bg;
        border: 1px solid $input-border-color;
        height: $input-size;
        margin: 0;
        outline: unset;
        position: relative;
        transition: all 0.15s ease;
        vertical-align: middle;
        width: $input-size;

        + label {
            cursor: pointer;
            display: flex;
            font-weight: 400;
            height: $input-size;
            line-height: $input-size;
            margin-bottom: 0;
            margin-left: $spacer-1;
            user-select: none;
            vertical-align: middle;
        }

        &::after {
            content: "";
            display: block;
            left: 0;
            position: absolute;
            top: 0;
        }

        &:checked {
            background: $brand-3;
            border-color: transparent;

            &::after {
                opacity: 1;
            }
        }

        &:disabled {
            background: $grey-5;
            cursor: not-allowed;
            opacity: 0.9;

            &:hover {
                background: $grey-4;
            }

            &:checked {
                background: $brand-4;
                border-color: $brand-3;
            }

            & + label {
                cursor: not-allowed;
            }
        }

        &:not(:disabled) {
            cursor: pointer;

            &:hover {

                &:not(:checked) {
                    background: $brand-4;
                    border-color: $brand-3;
                }
            }

            &:focus {
                border-color: transparent;
                box-shadow: 0 0 2px 2px $brand-3;
            }
        }
    }

    input[type="checkbox"] {
        border-radius: $spacer-1 * 0.75;

        &::after {
            border: 2px solid $brand-6;
            border-left: 0;
            border-top: 0;
            height: 9px;
            left: 7px;
            opacity: 0;
            top: 4px;
            transform: rotate(20deg); // (!) the check sign is a rotated border
            width: 5px;
        }

        &:checked {

            &::after {
                transform: rotate(43deg);
            }
        }
    }

    input[type="radio"] {
        border-radius: 50%;
        height: 20px;
        width: 20px;

        &::after {
            background: $default-6;
            border-radius: 50%;
            height: 18px;
            opacity: 0;
            width: 18px;
        }

        &:checked {

            &::after {
                transform: scale(0.5);
            }
        }
    }
}

