@use "theme/variables" as *;

.pdf-view {

    form {
        min-width: 450px;
    }

    .pdf-wrapper {
        display: flex;
        gap: $spacer-3;

        iframe {
            height: calc(100vh - 200px);
        }
    }
}
