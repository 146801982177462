// Spacing
$spacer-1: 8px;
$spacer-010: $spacer-1 * 0.1;
$spacer-025: $spacer-1 * 0.25;
$spacer-05: $spacer-1 * 0.5;
$spacer-2: $spacer-1 * 2;
$spacer-3: $spacer-1 * 3;
$spacer-4: $spacer-1 * 4;
$spacer-5: $spacer-1 * 5;
$spacer-6: $spacer-1 * 6;
$spacer-7: $spacer-1 * 7;
$spacer-8: $spacer-1 * 8;

// Fonts; pixel-based, instead of REM due to Bootstrap 3 dependency.
$font: "Inter";
$font-xxs: 0.6rem;
$font-xs: 0.7rem;
$font-s: 0.8rem;
$font-d: 0.85rem;
$font-l: 1rem;
$font-xl: 1.05rem;
$font-xxl: 1.5rem;

// Most icons use these sizes; one-off icon sizes should use $spacer-x
$icon-xs: $spacer-1 * 1.75;
$icon-s: $spacer-1 * 2.25;
$icon-d: $spacer-3;
$icon-l: $spacer-4;
$icon-xl: $spacer-1 * 4.5;
$icon-xxl: $spacer-8;
$icon-xxxl: $spacer-1 * 18;
$border-radius: $spacer-1;
$breakpoint-10: 500px; // 10-20 phone
$breakpoint-20: 750px; // 20-35 tablet
$breakpoint-30: 1079px; // ipad width
$breakpoint-35: 1400px; // 35-40 desktop
$breakpoint-40: 1550px; // 40-50 large desktop
$breakpoint-50: 1920px;

// Light & color definitions
$black: #000;
$grey-1: hsl(var(--grey-h) var(--s-0) var(--l-1));
$grey-2: hsl(var(--grey-h) var(--s-0) var(--l-2));
$grey-25: hsl(var(--grey-h) var(--s-0) var(--l-25));
$grey-3: hsl(var(--grey-h) var(--s-0) var(--l-3));
$grey-4: hsl(var(--grey-h) var(--s-0) var(--l-4));
$grey-5: hsl(var(--grey-h) var(--s-0) var(--l-5));
$grey-6: hsl(var(--grey-h) var(--s-0) var(--l-6));
$white: #fff;

// Surface colors may be changed for light/dark theming
$surface-1: hsl(var(--surface-h) var(--s-1) var(--l-1));
$surface-2: hsl(var(--surface-h) var(--s-1) var(--l-2));
$surface-25: hsl(var(--surface-h) var(--s-1) var(--l-25));
$surface-3: hsl(var(--surface-h) var(--s-1) var(--l-3));
$surface-4: hsl(var(--surface-h) var(--s-1) var(--l-4));
$surface-5: hsl(var(--surface-h) var(--s-1) var(--l-5));
$surface-6: hsl(var(--surface-h) var(--s-1) var(--l-6));

// The branding colors
$brand-1: hsl(var(--brand-h) var(--s-3) var(--l-1));
$brand-2: hsl(var(--brand-h) var(--s-3) var(--l-2));
$brand-25: hsl(var(--brand-h) var(--s-3) var(--l-25));
$brand-3: hsl(var(--brand-h) var(--s-3) var(--l-3));
$brand-4: hsl(var(--brand-h) var(--s-3) var(--l-4));
$brand-5: hsl(var(--brand-h) var(--s-3) var(--l-5));
$brand-6: hsl(var(--brand-h) var(--s-3) var(--l-6));

// Default state
$default-1: hsl(var(--default-h) var(--s-1) var(--l-1));
$default-2: hsl(var(--default-h) var(--s-1) var(--l-2));
$default-25: hsl(var(--default-h) var(--s-1) var(--l-25));
$default-3: hsl(var(--default-h) var(--s-1) var(--l-3));
$default-4: hsl(var(--default-h) var(--s-1) var(--l-4));
$default-5: hsl(var(--default-h) var(--s-1) var(--l-5));
$default-6: hsl(var(--default-h) var(--s-1) var(--l-6));

// Info state
$info-1: hsl(var(--info-h) var(--s-3) var(--l-1));
$info-2: hsl(var(--info-h) var(--s-3) var(--l-2));
$info-25: hsl(var(--info-h) var(--s-3) var(--l-25));
$info-3: hsl(var(--info-h) var(--s-2) var(--l-3));
$info-4: hsl(var(--info-h) var(--s-2) var(--l-4));
$info-5: hsl(var(--info-h) var(--s-2) var(--l-5));
$info-6: hsl(var(--info-h) var(--s-2) var(--l-6));

// Success state
$success-1: hsl(var(--success-h) var(--s-2) var(--l-1));
$success-2: hsl(var(--success-h) var(--s-2) var(--l-2));
$success-25: hsl(var(--success-h) var(--s-2) var(--l-25));
$success-3: hsl(var(--success-h) var(--s-2) var(--l-3));
$success-4: hsl(var(--success-h) var(--s-2) var(--l-4));
$success-5: hsl(var(--success-h) var(--s-2) var(--l-5));
$success-6: hsl(var(--success-h) var(--s-2) var(--l-6));

// Danger state
$danger-1: hsl(var(--danger-h) var(--s-3) var(--l-1));
$danger-2: hsl(var(--danger-h) var(--s-3) var(--l-2));
$danger-25: hsl(var(--danger-h) var(--s-3) var(--l-25));
$danger-3: hsl(var(--danger-h) var(--s-3) var(--l-3));
$danger-4: hsl(var(--danger-h) var(--s-3) var(--l-4));
$danger-5: hsl(var(--danger-h) var(--s-3) var(--l-5));
$danger-6: hsl(var(--danger-h) var(--s-3) var(--l-6));

// Warning state
$warning-1: hsl(var(--warning-h) var(--s-3) var(--l-1));
$warning-2: hsl(var(--warning-h) var(--s-3) var(--l-2));
$warning-25: hsl(var(--warning-h) var(--s-3) var(--l-25));
$warning-3: hsl(var(--warning-h) var(--s-3) var(--l-3));
$warning-4: hsl(var(--warning-h) var(--s-3) var(--l-4));
$warning-5: hsl(var(--warning-h) var(--s-3) var(--l-5));
$warning-6: hsl(var(--warning-h) var(--s-3) var(--l-6));

// Consistent non-theme-specific color for legacy warnings.
$warning-legacy-h: 50deg;
$warning-legacy-s: 90%;
$warning-legacy-1: hsl($warning-legacy-h $warning-legacy-s var(--l-1));
$warning-legacy-2: hsl($warning-legacy-h $warning-legacy-s var(--l-2));
$warning-legacy-3: hsl($warning-legacy-h $warning-legacy-s var(--l-3));
$warning-legacy-4: hsl($warning-legacy-h $warning-legacy-s var(--l-4));
$warning-legacy-5: hsl($warning-legacy-h $warning-legacy-s var(--l-5));
$warning-legacy-6: hsl($warning-legacy-h $warning-legacy-s var(--l-6));

// Default shadows
$shadow-color: hsla(0deg 0% 0% / 20%);
$shadow-down-s: 0 $spacer-1 * 0.1 $spacer-1 * 0.25 $shadow-color;
$shadow-down-d: 0 $spacer-1 * 0.1 $spacer-1 * 0.5 $shadow-color;
$shadow-down-d-r: $spacer-1 * 0.25 0 $spacer-1 * 0.5 $shadow-color;
$shadow-down-d-l: $spacer-1 * -0.25 0 $spacer-1 * 0.5 $shadow-color;
$shadow-down-l: 0 $spacer-1 * 0.5 $spacer-1 $shadow-color;
$shadow-filter: drop-shadow(0 6px 3px $shadow-color);

// Used for left and right panels
$shadow-down-d-r: $spacer-1 * 0.25 0 $spacer-05 hsla(var(--surface-h) var(--s-1) var(--l-1) / 30%);
$shadow-down-d-l: $spacer-1 * -0.25 0 $spacer-05 hsla(var(--surface-h) var(--s-1) var(--l-1) / 30%);
$shadow-down-d-xl: $spacer-1 * -0.5 0 $spacer-2 hsla(var(--surface-h) var(--s-1) var(--l-1) / 60%);

// Gradients for panel backgrounds
$info-gradient: linear-gradient(to bottom, $info-1, hsl(var(--info-h) var(--s-3) calc(var(--l-1) + 2%)));

// Forms
$input-height: $spacer-5;
$input-label-color: $grey-1;
$input-label-height: $spacer-3;
$input-bg: hsl(var(--surface-h) var(--s-1) 100%);
$input-margin: $spacer-3;
$validation-unset-border: 1px solid hsl(var(--surface-h) var(--s-1) var(--l-3));
$validation-valid-border: 1px solid hsl(var(--success-h) var(--s-1) var(--l-2));
$validation-invalid-border: 1px solid hsl(var(--danger-h) var(--s-1) var(--l-2));
$focus-outline: 1px solid hsl(var(--info-h) var(--s-2) var(--l-3));
$focus-outline-invalid: $danger-2;
$input-border-color: $surface-4;
$input-disabled-bg: $grey-5;
$input-disabled-color: hsl(var(--surface-h) var(--s-1) var(--l-2));
$input-disabled-border: $grey-4;
$input-active-border: $info-2;
$input-color: hsl(var(--surface-h) var(--s-1) var(--l-1));
$input-placeholder-color: hsl(var(--surface-h) var(--s-1) var(--l-2));
$table-header-bg: $info-1;
$table-header-color: $info-4;
$table-header-height: $spacer-6;
$border-color: $grey-5;
$border-radius-xl: $spacer-2;
$border-radius-l: $spacer-1 * 1.5;
$border-radius-d: $spacer-1;
$border-radius-s: $spacer-05;
$border-radius-xs: $spacer-05;
$outline: 0 0 1px 2px;
$outline-inset: inset 0 0 0 1px;
$panel-width: 450px;
$panel-width-l: 550px;
$panel-filters-width: 275px;
$panel-collapsed-width: $spacer-6 + $spacer-025;
$layout-header-height: $spacer-8;
$layout-submenu-height: $spacer-6;

:root {
    // 3 default saturation levels & 6 lightness levels
    --s-0: 3%;
    --s-1: 13%;
    --s-2: 30%;
    --s-3: 80%;
    --l-1: 25%;
    --l-2: 35%;
    --l-25: 55%;
    --l-3: 65%;
    --l-4: 87%;
    --l-5: 94%;
    --l-6: 97%;

    // Signal colors use predefined saturation levels: --s-0 to --s-3
    // These colors use lightness levels --l-1 to --l-6
    --info-h: 193deg;
    --success-h: 101deg;
    --danger-h: 5deg;
    --warning-h: 25deg;
    --surface-h: 195deg;
    --default-h: var(--surface-h);
    --grey-h: var(--surface-h);

    // Brand-color uses a fixed saturation level with
    // lightness levels --l-1 to --l-6
    --brand-s: 65%;
    --coll-status-width: #{$icon-d + $spacer-05};
    --breakpoint-10: 500px;
    --breakpoint-20: 750px;
    --breakpoint-30: 1079px;
    --breakpoint-35: 1400px;
    --breakpoint-40: 1550px;
    --breakpoint-50: 1920px;
}

// Bootrap-mini injected variable (Discover only)
$icon-font-path: "/fonts/";
