@use "../variables" as *;
@forward "../variables";

$themedir: "../../theme/msi/img";

:root {
    --brand-h: 353deg;
    --info-h: 193deg;
    --surface-h: 28deg;
    --success-h: 130.2deg;
}
