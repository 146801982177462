@use "common/lib/mixins" as *;
@use "theme/variables" as *;
@use "../lib/functions" as *;

// Common styling for legacy .table & .c-collection-items
$well-background: $info-4;

.collection-widget {
    background: $white;
    box-shadow: $shadow-down-d;

    > .header {
        align-items: center;
        background: $default-5;
        border-bottom: 1px solid $info-4;
        color: $default-2;
        display: flex;
        gap: $spacer-1;
        height: $spacer-6;
        padding: 0 $spacer-1;

        .title {
            align-items: center;
            display: flex;
            flex-direction: row;
            font-weight: 600;
            gap: $spacer-1;
            height: $spacer-6;
            width: 100%;

            .header {
                align-items: center;
                display: flex;
                flex-grow: 1;
                gap: $spacer-1;
            }
        }

        .options {
            display: flex;
            flex: 1;
            justify-content: flex-end;
        }

        .legenda {
            display: flex;
            gap: $spacer-05;

            .c-icon {
                &.is-current-supplier-price-list { color: hs-current-spli(4); }
                &.is-competitor { color: hs-competitor(4); }
                &.is-agent-importer-nl { color: hs-agent-nl(4); }
                &.is-msp { color: hs-msi(4); }
            }
        }
    }

    > .content {
        padding: $spacer-025;

        .placeholder {
            color: $default-2;
            font-weight: 600;
            padding: $spacer-1;
        }

        .table {
            margin-bottom: 0;

            tr {

                td {
                    border: 0;
                }
            }

            thead {

                th {
                    background: $info-1;
                    color: $info-5;
                    padding: $spacer-05 $spacer-1;
                }
            }

            tbody {

                .c-custom-offer-market-item {
                    border-left: 2px solid $info-3;
                    font-style: italic;
                }
            }
        }

        .btn-show-more {
            margin: $spacer-025;
        }
    }
}

.table {
    background: none;
    border-spacing: 0;
    margin: 0;
    width: 100%;

    :not(.table-row) tr:not(.even,.odd),
    .table-row {

        &:nth-child(odd) {
            @include row-style($info-6, $info-4, $grey-1);
        }

        &:nth-child(even) {
            @include row-style($info-5, $info-4, $grey-1);
        }
    }

    thead {
        color: $info-5;
        height: $table-header-height;

        th {
            background: $table-header-bg;
            color: $table-header-color;
            height: $spacer-5;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    td,
    th {
        padding: $spacer-1;
        vertical-align: middle;
        &.size-1 { width: 2%; }
        &.size-2 { width: 5%; }
        &.size-3 { width: 10%; }
        &.size-4 { width: 20%; }

        .btn-toolbar {
            margin-bottom: 0;
        }

        input[type="number"] {
            max-width: 100px;
        }

        .select-all {
            display: none;
        }

        &.center {
            text-align: center;
        }
    }

    tbody {
        border: 0 !important;  // Bootstrap override
        cursor: default;  // default action for a table is currently to open some other page

        tr.collapsed {
            cursor: ns-resize;
        }

        tr {

            &.odd {
                // A lot of tables have an extra detail tr beneath the main tr.
                // The .odd/.even styling is required in those cases. Otherwise
                // we would use :nth-child.
                @include row-style($info-6, $info-4, $grey-1);
            }

            &.even {
                @include row-style($info-5, $info-4, $grey-1);
            }

            &.is-competitor {
                @include row-style(hs-competitor(4), hs-competitor(4), hs-competitor(1), true);

                border-left: $spacer-025 solid hs-competitor(2);
            }

            &.is-agent-importer-nl {
                @include row-style(hs-agent-nl(4), hs-agent-nl(4), hs-agent-nl(1), true);
            }

            &.is-current-supplier-price-list {
                @include row-style(hs-current-spli(4), hs-current-spli(4), hs-current-spli(1), true);
            }

            &.is-msp {
                @include row-style(hs-msi(4), hs-msi(4), hs-msi(1), true);

                border-left: $spacer-025 solid hs-msi(2);
            }

            &.is-current-offer {
                @include row-style(hs-msi(3), hs-msi(3), hs-msi(1), true);
            }

            .detail-row,
            &.well {
                background: $well-background;
                cursor: default;

                .columns {
                    margin-bottom: 0 !important;
                }
            }

            &.well {
                width: 100%;

                > td {
                    padding: 0;

                    .well-container {
                        background: $well-background;
                        display: flex;
                        flex-direction: column;
                        gap: $spacer-2;
                        padding: $spacer-1;

                        .columns {
                            gap: $spacer-2;
                            margin: 0;

                            .column {
                                padding: 0;
                            }
                        }
                    }
                }
            }

            td {
                border-top: 1px solid $info-4;

                &.actions {
                    align-items: flex-end;
                    padding: 0;
                    padding-right: $spacer-1;
                    white-space: nowrap;

                    .actions-group {
                        align-items: center;
                        display: flex;
                        gap: $spacer-3;
                        justify-content: flex-end;

                        .action-toggles {
                            align-items: center;
                            display: flex;
                            margin-left: $spacer-2;
                        }
                    }
                }

                &.ellipsis {
                    background-color: inherit;
                    cursor: default;
                    max-width: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:hover {
                        overflow: visible;
                        position: relative;

                        span {
                            background-color: inherit;
                            color: $default-1;
                            display: inline-block;
                        }
                    }
                }

                .td-group {
                    display: flex;
                    flex-direction: column;

                    .header {
                        align-items: center;
                        color: $info-1;
                        display: flex;
                        font-weight: 600;
                    }

                    .details {
                        font-size: $font-s;
                        font-weight: italic;
                    }

                    .marker {
                        align-items: center;
                        display: flex;
                        gap: $spacer-05;
                    }
                }

                &.price {

                    .row-fields {
                        justify-content: flex-end;
                    }
                }

                .row-fields {
                    display: flex;
                    gap: $spacer-05;

                    .field {
                        align-self: flex-end;
                    }
                }
            }
        }
    }

    .field {
        // Single fields in a row must have not have margin
        margin-bottom: 0;
    }

    .fieldset {
        // And the exception; field in a fieldset in a table must have
        // regular margin-bottom.

        .field {
            margin-bottom: $spacer-2;
        }
    }
}
