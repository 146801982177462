@use "theme/variables" as *;

.nav-tabs {
    display: flex;
    width: 100%;

    li {
        margin-right: $spacer-1;
        padding: 0;

        a {
            border: 0 !important;
            border-color: $default-4;
            border-radius: $spacer-1 $spacer-1 0 0;
            color: $surface-2;
            cursor: pointer;
            margin: 0;
            transition: all 0.3s ease;
            user-select: none;
        }

        &:focus a,
        &.active {

            a:hover,
            a:focus,
            a {
                background: $info-2;
                color: $info-5;
                cursor: pointer;
            }
        }

        &.disabled {

            a {
                background: $grey-5;
                color: $grey-4;
            }

            &:hover {

                a {
                    background: $grey-5;
                    color: $grey-4;
                }
            }
        }

        &:not(.disabled) {

            &.active {
                a { background: $info-2; }
            }

            &:not(.active) {

                a {
                    background: $default-5;
                    color: $default-2;
                }

                &:hover {

                    a {
                        background: $default-4;
                    }
                }
            }
        }
    }
}

.c-tabs {

    .c-tab {
        display: none;
        padding: $spacer-2 0;

        &.active {
            display: block;
        }
    }
}
