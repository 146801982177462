@use "common/lib/mixins" as *;
@use "theme/variables" as *;

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

body {
    color: $grey-1;
    font-family: $font;
    font-size: $font-d;
    line-height: 1.25 * $font-d;
    margin: 0;
}

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a {
    color: $brand-3;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $brand-2;
    }

    &:focus {
        // WebKit-specific. Other browsers will keep their default outline style.
        // (Initially tried to also force default via `outline: initial`,
        // but that seems to erroneously remove the outline in Firefox altogether.)
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }
}

figure {
    margin: 0;
}

img {
    vertical-align: middle;
}

// Responsive images (ensure images don't scale beyond their parents)

.img-responsive {
    @include img-responsive;
}

hr {
    border: 0;
    border-top: 1px solid $grey-4;
    margin-bottom: 1.2 * $font-d;
    margin-top: 1.2 * $font-d;
}

// iOS "clickable elements" fix for role="button"
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
    cursor: pointer;
}
