@use "theme/variables" as *;

$handle-width: $spacer-3;

.noUi-target {
    background: $default-5;
    border: 1px solid $default-4;
    border-radius: $spacer-05;
    box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
    position: relative;

    &,
    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
        touch-action: none;
        -webkit-touch-callout: none;
        user-select: none;
    }

    .noUi-handle {

        .noUi-touch-area {
            height: 100%;
            width: 100%;
        }
    }

    &.noUi-horizontal {
        height: $spacer-1 * 1.5;

        .noUi-handle {
            height: 28px;
            right: -0.5 * $handle-width;
            top: -8px;
            width: $handle-width;
        }

        .noUi-origin {
            height: 0;

            .noUi-handle {
                backface-visibility: hidden;
                background: $default-6;
                border: 1px solid $default-4;
                border-radius: 3px;
                box-shadow:
                    inset 0 0 1px $default-6,
                    inset 0 1px 7px $default-5,
                    0 3px 6px -3px $default-4;
                cursor: default;
                position: absolute;

                &::before {
                    background: $default-4;
                    content: "";
                    display: block;
                    height: 14px;
                    left: 14px;
                    position: absolute;
                    top: 6px;
                    width: 1px;
                }

                .noUi-tooltip {
                    align-items: center;
                    border-radius: $spacer-05;
                    color: $info-1;
                    display: flex;
                    font-size: $font-xs;
                    font-weight: 600;
                    height: $spacer-4;
                    padding: 0 $spacer-05;
                    position: absolute;
                    text-align: center;
                    white-space: nowrap;
                    z-index: 1000;
                }
            }
        }

        .noUi-tooltip {
            bottom: 120%;
            left: 50%;
            transform: translate(-50%, 0);
        }

        .noUi-origin > .noUi-tooltip {
            bottom: 10px;
            left: auto;
            transform: translate(50%, 0);
        }
    }

    &.noUi-vertical {
        width: 18px;

        .noUi-handle {
            bottom: -0.5 * $handle-width;
            height: $handle-width;
            right: -6px;
            width: 28px;
        }

        .noUi-origin {
            top: -100%;
            width: 0;
        }

        .noUi-tooltip {
            right: 120%;
            top: 50%;
            transform: translate(0, -50%);
        }


        .noUi-origin > .noUi-tooltip {
            right: 28px;
            top: auto;
            transform: translate(0, -18px);
        }

        .noUi-handle::before,
        .noUi-handle::after {
            height: 1px;
            left: 6px;
            top: 14px;
            width: 14px;
        }

        .noUi-handle::after {
            top: $handle-width;
        }

        .noUi-draggable {
            cursor: ns-resize;
        }
    }


    .noUi-base {

        .noUi-connects {
            border-radius: 3px;
            overflow: hidden;
            z-index: 0;

            .noUi-connect {
                background: $brand-3;
                transition: background-color 0.3s ease;
            }
        }
    }

    .noUi-pips {
        color: $default-2;
        position: absolute;

        &.noUi-pips-horizontal {
            left: 0;
            padding: 10px 0;
            top: 100%;
            width: 100%;
        }

        &.noUi-pips-vertical {
            height: 100%;
            left: 100%;
            padding: 0 10px;
            top: 0;
        }

        .noUi-value-horizontal {
            font-size: $font-xs;
            transform: translate(-50%, 50%);

            .noUi-rtl & {
                transform: translate(50%, 50%);
            }
        }

        .noUi-value-vertical {
            padding-left: 25px;
            transform: translate(0, -50%);

            .noUi-rtl & {
                transform: translate(0, 50%);
            }
        }

        .noUi-value {
            position: absolute;
            text-align: center;
            white-space: nowrap;
        }

        .noUi-value-sub {
            color: #ccc;
            font-size: 10px;
        }

        .noUi-marker {
            background: $default-4;
            position: absolute;

            &.noUi-marker-horizontal {

                &.noUi-marker {
                    height: $spacer-05;
                    margin-left: -1px;
                    width: 1px;
                }

                &.noUi-marker-sub {
                    height: $spacer-1;
                }

                &.noUi-marker-large {
                    height: $spacer-1;
                }
            }

            &.noUi-marker-vertical {

                &.noUi-marker {
                    height: 2px;
                    margin-top: -1px;
                    width: 5px;
                }

                &.noUi-marker-sub {
                    width: 10px;
                }

                &.noUi-marker-large {
                    width: 15px;
                }
            }

            &.noUi-marker-sub {
                background: #aaa;
            }

            &.noUi-marker-large {
                background: #aaa;
            }
        }
    }
}

.noUi-base,
.noUi-connects {
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
}

.noUi-connect,
.noUi-origin {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: 0 0;
    transform-style: preserve-3d;
    transform-style: flat;
    width: 100%;
    will-change: transform;
    z-index: 1;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
    left: 0;
    right: auto;
}


.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    transition: transform 0.3s;
}
