@use "theme/variables" as *;

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src: url("/common/fonts/inter-latin-300-normal.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src: url("/common/fonts/inter-latin-400-normal.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    src: url("/common/fonts/inter-latin-400-italic.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src: url("/common/fonts/inter-latin-500-normal.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src: url("/common/fonts/inter-latin-600-normal.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    src: url("/common/fonts/inter-latin-700-normal.woff2") format("woff2");
}
