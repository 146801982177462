@use "theme/variables" as *;
@use "theme/msp" as *;

@keyframes bounce {

    0% {
        transform: scale(1);
    }
    50% { transform: scale(1.2); }

    100% {
        transform: scale(1);
    }
}

@keyframes fade-in {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes expand {

    0%,
    50% {
        box-shadow: $shadow-down-d;
        max-height: $spacer-8;
    }

    100% {
        box-shadow: $shadow-down-d-l;
        max-height: 100%;
    }
}
