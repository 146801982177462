@use "theme/variables" as *;

.process {
    display: flex;

    // For now, assume only the horizontal ProcessStepper is being used;
    // the vertical variant may be applicable in CRM forms or where
    // horizontal space is less of an issue.
    flex-direction: column;
    gap: $spacer-3;
    position: relative;

    .step-content {
        flex: 1;
        position: relative;
    }
}
