@use "theme/variables" as *;
@use "common/lib/mixins" as *;

.panel {
    margin-bottom: unset;  // bootstrap override Discover

    .toggles {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 1.5 * $spacer-1;
        margin-top: $spacer-1;

        .toggle {
            @include toggle;

            position: relative;

            .amount {
                bottom: -1.75 * $spacer-1;
            }

            &.active {
                display: flex;
            }

            &:not(.active) {
                animation: 0.75s toggle-panel-collapse;
            }

            &.panel-left-toggle {

                &:not(.collapsed) {
                    animation: 0.75s toggle-panel-collapse;
                    bottom: $spacer-1;
                    position: absolute;
                    right: $spacer-1;
                }

                &.collapsed {
                    animation: 0.75s toggle-panel-uncollapse;
                    position: relative;
                }
            }
        }
    }

    header {
        align-items: center;
        background: $default-5;
        border-bottom: 2px solid $default-4;
        color: $default-1;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-size: $font-l;
        justify-content: space-between;
        padding: 0 $spacer-1;
        padding-right: 0;
        width: 100%;

        &:hover {
            background: $success-5;
            border-bottom: 2px solid $success-4;
            color: $success-1;
        }

        .title {
            align-items: center;
            display: flex;
            font-weight: 600;
            height: $spacer-8;
            text-transform: uppercase;

            svg {
                margin-right: $spacer-05;
            }
        }
    }
}

.paneled {
    display: flex;

    .content {
        flex: 1;
        height: 100%;
    }
}

.btn-panel {
    background: $default-5;
    width: 100%;
}
