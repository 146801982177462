@use "theme/msp";
@use "theme/variables" as *;
@use "common/common";
@use "sass:math";

// Discover-specific
@use "legacy";
@use "elements/pdf-view";
@use "elements/tabs";

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}

html {
    overflow-y: hidden;
}

span.multiselect {

    button.multiselect {
        text-align: left;
    }

    // do not set to display: none, validation does not work than.

    input.sneaky-validator {
        height: 15px;
        opacity: 0.01;
        padding: 0;
        position: absolute;
        top: 0;
        width: 70px;
        z-index: -100;
    }
}

input.no-spinner::-webkit-outer-spin-button,
input.no-spinner::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

.competitor { background-color: hs-competitor(4) !important; }
.agent-importer-nl { background-color: hs-agent-nl(4) !important; }
.msi { background-color: hs-msi(4) !important; }
.a2bc { background-color: hs-a2bc(4) !important; }

h3,
h4 {
    margin-top: 0;
}

h3 {
    color: hsl(var(--info-h) var(--s-3) var(--l-1));
    font-size: 18px;
}

.container-narrow > hr {
    margin: 30px 0;
}

table.search-table {

    thead th label {
        margin-bottom: 10px;
    }

    thead th {
        z-index: 2;
    }

    th.sticky {
        position: sticky;
        top: -1 * $spacer-2;
    }
}

/* Responsive: Portrait tablets and up */

.text-xl {
    font-size: 140%;
}

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: $spacer-1; }
.mb-2 { margin-bottom: $spacer-2; }

.mt-1 { margin-top: $spacer-1; }
.mr-05 { margin-right: $spacer-05; }
.ml-05 { margin-left: $spacer-05; }

.pl-2 { padding-left: $spacer-2; }

.btn-spotlight--active {
    background-color: $grey-5;
    border-color: $warning-2;
    color: $warning-3;

    &:hover,
    &:active,
    &:focus {
        background-color: $grey-5;
        border-color: $warning-1;
        color: $warning-3;
    }
}

.bold {
    font-weight: bold;
}

input:invalid {
    border: 1px solid $danger-3;
}

.price {
    padding-right: 15px;
    text-align: right;
}

.number,
.colored-number {
    padding-right: 30px;
    text-align: right;
}

.colored-number[data-value]::after {
    color: $success-3;
    content: attr(data-value);
}

.colored-number[data-value^="-"]::after {
    color: $danger-3;
}

select.column-mapping {
    max-width: 200px;
}

button.disabled,
select.disabled {
    color: $grey-3;
}

.header-cell {
    height: 20px;
}

.glyphicon-spin {
    animation: spin 1000ms infinite linear;
}

@keyframes spin {

    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

.no-select {
    -webkit-touch-callout: none;
    user-select: none;
}

.no-padding-left {
    padding-left: 0;
}

.no-padding-right {
    padding-right: 0;
}

.btn {

    &:active,
    &:focus {
        outline: 0;
    }
}

.clickable {
    cursor: pointer;
}

a.discover-link {
    float: right;
}

.sticky {
    position: fixed;
    z-index: 500;
}

.alphabet {
    width: math.percentage(math.div(1, 26));
}

.is-current-supplier {
    font-weight: bold;
}

.vertical-divider {
    border-right: 1px solid $border-color;
}

.spli-suggestion {
    padding: $spacer-1;

    &:hover {
        background-color: $default-4;
        cursor: pointer;
    }
}


.bad-color {
    color: $danger-2;
}

.target-price-per-case-not-used {
    color: $grey-3;
}

.eierballs {
    background-image: url("/img/eierbal.png");
    background-size: 100%;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
    z-index: -200;
}

.bs-callout {
    border: 1px solid $grey-5;
    border-left-width: 5px;
    border-radius: 3px;
    display: inline;
    margin-left: 4px;
    margin-right: 4px;
    padding: 6px;

    &.bs-callout-primary {
        border-left-color: #428bca;
    }

    &.bs-callout-success {
        border-left-color: #5cb85c;
    }

    &.bs-callout-danger {
        border-left-color: #d9534f;
    }

    &.bs-callout-warning {
        border-left-color: #f0ad4e;
    }

    &.bs-callout-info {
        border-left-color: #5bc0de;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.offer {

    input.has-change {
        background-color: #fff464;
    }
}

table.table.suggestion {
    margin-bottom: 0;
}

table.table tr.well > td > .well-container {
    display: flex;
    flex-direction: column;
    gap: $spacer-2;
    margin-bottom: $spacer-1;
    padding: 0;
    padding-left: $spacer-1;
    padding-right: $spacer-1;
}

.tag-label {
    color: $grey-1;
    display: inline-block;
    font-size: $font-xxs;
    font-weight: 600;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: $spacer-025 $spacer-1;
}

.tag-label-icon {
    color: $grey-1;
    display: inline-block;
    font-size: 10pt;
    margin-bottom: 5px;
    margin-right: 5px;
}

.filter-sidebar-wrapper {
    align-items: stretch;
    display: flex;
}

.filter-result {
    padding-left: 10px;
    width: 100%;
}

.filter-radio {
    /* Make sure that radiobutton filters are wrapped vertically in filter sidebars. */
    display: block;
}

dl.text-left {

    dt {
        text-align: left;
    }
}

.clipboard-copy {
    cursor: pointer;

    &:hover {
        color: $grey-3;
    }

    &:active {
        color: $brand-3;
    }
}
