@use "theme/variables" as *;

body {
    .bold { font-weight: 600; }

    .green {
        color: $success-25;
        font-weight: 700;
    }

    .red {
        color: $danger-3;
        font-weight: 700;
    }

    .m-0 { margin: 0; }
    .m-1 { margin: $spacer-1; }
    .m-1-5 { margin: $spacer-1 * 1.5; }
    .m-2 { margin: $spacer-2; }

    .mb-0 { margin-bottom: 0; }
    .mb-1 { margin-bottom: $spacer-1; }
    .mb-2 { margin-bottom: $spacer-2; }
    .mb-3 { margin-bottom: $spacer-3; }

    .mt-0 { margin-top: 0; }
    .mt-1 { margin-top: $spacer-1; }
    .mt-2 { margin-top: $spacer-2; }
    .mt-3 { margin-top: $spacer-3; }

    .mr-0 { margin-right: 0; }
    .mr-05 { margin-right: $spacer-05; }
    .mr-1 { margin-right: $spacer-1; }
    .mr-2 { margin-right: $spacer-2; }
    .mr-3 { margin-right: $spacer-3; }

    .ml-0 { margin-left: 0; }
    .ml-05 { margin-left: $spacer-05; }
    .ml-1 { margin-left: $spacer-1; }
    .ml-2 { margin-left: $spacer-2; }
    .ml-3 { margin-left: $spacer-3; }

    .p-0 { padding: 0; }
    .pl-0 { padding-left: 0; }
    .pl-1 { padding-left: $spacer-1; }
    .pl-2 { padding-left: $spacer-2; }
    .pl-3 { padding-left: $spacer-3; }

    .pt-0 { padding-top: 0; }
    .pt-1 { padding-top: $spacer-1; }
    .pt-2 { padding-top: $spacer-2; }
    .pt-3 { padding-top: $spacer-3; }

    .mw-70 {
        max-width: 70px;
    }

    .mw-120 {
        max-width: 120px;
    }

    .gap-2 {
        gap: $spacer-2;
    }

    .fl-end {
        justify-content: flex-end;
    }

    .fl-column {
        display: flex;
        flex-direction: column;
    }

    .fl {
        align-items: center;
        display: flex;
    }

    .fl-c {
        align-items: center;
        display: flex;
        white-space: nowrap;
    }
    .fl-1 { flex: 1; }
    .fl-2 { flex: 2; }
    .fl-3 { flex: 3; }
    .fl-4 { flex: 4; }
    .fl-5 { flex: 5; }
    .fl-6 { flex: 6; }
    .text-s { font-size: $font-s; }
    .text-d { font-size: $font-d; }
    .text-l { font-size: $font-l; }
    .text-xl { font-size: $font-xl; }


    .center-block {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .pull-right { float: right; }
    .pull-left { float: left; }

    .hide { display: none; }
    .show { display: block; }
    .invisible { visibility: hidden; }
    .hidden { display: none; }
}

